import styles from "./Welcome1.module.css";

const Welcome1 = () => {
  return (
    <div className={styles.welcome1}>
      <img className={styles.vectorIcon} alt="" src="/vector.svg" />
      <img className={styles.groupIcon} alt="" src="/group.svg" />
      
      <div className={styles.maskGroup}>
        <div className={styles.group1}>
          <div className={styles.googleVertexAi}>Google Vertex AI Demo</div>
        </div>
      </div>
      <img className={styles.groupIcon1} alt="" src="/group1.svg" />
    </div>
  );
};

export default Welcome1;
